import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { jwtDecode } from 'jwt-decode'
import { useGift } from '@/composables/api/useGift'
import type { CampaignGiftGetResponseBody } from '@/types/campaign'
import type {
  MagicLinkStartReqInput,
  MagicLinkLoginReqInput,
} from '@/types/magicLink'
import { type UserJWT, UserRole } from '@/types/jwt'
import {
  persistedState,
  useGtmTrackEvent,
  useMagentoCookieState,
  useSiteStoreCode,
} from '#imports'
import { useZenniFetch } from '@/composables/useZenniFetch'
import { useMagicLink } from '@/composables/api/useMagicLink'
import type {
  StoreProps,
  UserInfo,
  GoogleJWT,
  LoginResponse,
  UpdateStoreResponse,
  EyebotLoginReqInput,
} from '@/types/loginUser'
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import { getCookieDomain } from '@/utils/helper/getCookieDomain'
import { useUserApi } from '@/composables/api/useUser'

export const useUserStore = defineStore(
  'user',
  () => {
    const userApi = useUserApi()
    const magicLinkApi = useMagicLink()
    const magentoCookieState = useMagentoCookieState()
    const siteConfigurationStore = useSiteConfigurationStore()
    const credential = ref<string>('')
    const { isEyeBotSiteStore } = useSiteStoreCode()
    const userToken = ref<string>('')
    const user = ref<UserInfo>()
    const roles = ref<UserRole[] | undefined>()
    const currentRole = ref<UserRole>()
    const loggedIn = ref<boolean>(false)
    const eyeBotSessionId = ref<string>('')
    const store = ref<StoreProps | null>(null)
    const loginError = ref<boolean>(false)
    const giftApi = useGift()
    const { loginTrackEvent } = useGtmTrackEvent()
    const initials = computed<string>(() =>
      user.value?.firstName && user.value?.lastName
        ? `${user.value.firstName[0]}${user.value.lastName[0]}`
        : '',
    )
    const redeemedStatus = ref<any>(null)
    const userGift = ref<CampaignGiftGetResponseBody>()
    const userGiftType = ref<string | null>()
    const loading = ref<boolean>(false)

    // TODO Need to get the loginType from the api layer

    const setEyebotLoginState = (sessionId: string, data: any) => {
      eyeBotSessionId.value = sessionId
      setLoginState(data)
    }

    const setLoginState = (response: LoginResponse) => {
      const {
        zenniId,
        roles: userRoles,
        firstName,
        lastName,
        email,
      } = jwtDecode<UserJWT>(response.backendToken)
      roles.value = userRoles
      currentRole.value = assignRole()
      userToken.value = response.backendToken
      user.value = {
        zenniId,
        firstName,
        lastName,
        email,
        phone: '',
        subscribe: false,
      }
      store.value = response.store
      magentoCookieState.setStore(store.value.storeCode, {
        domain: getCookieDomain(),
      })
      loginTrackEvent(zenniId, store.value?.partnerId ?? '')
      loggedIn.value = true
      loginError.value = false
      redeemedStatus.value = null
    }

    const updateUserWithUserData = (data: any) => {
      user.value = {
        ...(user.value as UserInfo),
        phone: data.mobilePhone,
        subscribe: data.subscribe,
      }
    }

    const logout = (): void => {
      loggedIn.value = false
      store.value = null
      roles.value = undefined
      currentRole.value = undefined
      credential.value = ''
      userToken.value = ''
      user.value = {} as UserInfo
      redeemedStatus.value = null
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('VTO_DATA')
        localStorage.removeItem('PRESCRIPTION_DATA')
        sessionStorage.removeItem('FILTERS')
        sessionStorage.removeItem('PAYMENT_GIFT_TYPE')
        sessionStorage.removeItem('PAYMENT_DETAILS')
      }
      magentoCookieState.removeCartIds()
      magentoCookieState.removeStore()
    }

    const setErrorState = (status: boolean = true): void => {
      loginError.value = status
      if (status) {
        logout()
      }
    }

    const startMagicLink = async (
      params: MagicLinkStartReqInput,
    ): Promise<boolean> => {
      setErrorState(false)
      try {
        const { error } = await magicLinkApi.start(params)

        if (error.value) {
          setErrorState()
          return false
        }
      } catch (e) {
        setErrorState()
        return false
      }
      return true
    }

    const loginWithMagicLink = async (
      params: MagicLinkLoginReqInput,
    ): Promise<void> => {
      loginError.value = false
      const { data, error } = await useZenniFetch<LoginResponse>('/api/login', {
        method: 'POST',
        body: {
          ...params,
        },
      })

      if (error.value) {
        setErrorState()
      } else if (data.value) {
        setErrorState(false)
        setLoginState(data.value)
        await getUserData()
      }
    }

    const loginWithGoogle = async (): Promise<void> => {
      loginError.value = false
      const { email } = jwtDecode<GoogleJWT>(credential.value)

      const { data, error } = await useZenniFetch<LoginResponse>('/api/login', {
        method: 'POST',
        body: {
          credential: credential.value,
          userEmail: email,
          loginType: 'sso.google',
          ...(process.env.NODE_ENV === 'development' && {
            redirectUri: 'http://localhost:3002',
          }),
        },
      })

      if (error.value) {
        setErrorState()
      } else if (data.value) {
        setErrorState(false)
        setLoginState(data.value)
        await getUserData()
      }
    }

    const loginWithEyebot = async (
      params: EyebotLoginReqInput,
    ): Promise<void> => {
      loginError.value = false
      const { data, error } = await useZenniFetch<LoginResponse>('/api/login', {
        method: 'POST',
        body: {
          ...params,
        },
      })

      if (error.value) {
        setErrorState()
      } else if (data.value) {
        setErrorState(false)
        setEyebotLoginState(params.code, data.value)
        await getUserData()
      }
    }

    const updateStore = async (storeId: string): Promise<void> => {
      loading.value = true

      try {
        const { data, error } = await useZenniFetch<UpdateStoreResponse>(
          '/api/switch-store',
          {
            method: 'POST',
            body: {
              token: userToken.value,
              storeId,
            },
          },
        )

        if (error.value || !data.value) return

        const { backendToken, store: newStore } = data.value
        const { roles: fetchedRoles } = jwtDecode<UserJWT>(backendToken)

        if (fetchedRoles?.length) {
          roles.value = fetchedRoles
          currentRole.value = assignRole()
        }

        if (newStore) {
          store.value = newStore
          magentoCookieState.setStore(newStore.storeCode, {
            domain: getCookieDomain(),
            path: '/',
          })
        }

        if (backendToken) {
          userToken.value = backendToken
        }

        // Update site configuration
        if (newStore?.storeCode) {
          await siteConfigurationStore.getSiteConfiguration(
            newStore.storeCode.replace('_', ' '),
          )
        }
      } finally {
        loading.value = false
      }
    }

    const getUserGift = async (): Promise<CampaignGiftGetResponseBody> => {
      const gift = await giftApi.getGift({
        params: {
          email: user.value?.email,
          isActivated: true,
          idempotencyKey: isEyeBotSiteStore.value
            ? eyeBotSessionId.value
            : undefined,
        },
      })

      userGift.value = gift
      return gift
    }

    const getUserGiftType = async () => {
      if (userGiftType.value !== null) {
        return userGiftType.value
      }
      const gift = await getUserGift()
      userGiftType.value = gift.usage?.type ?? null
      return userGiftType.value
    }

    const checkIsRedeemed = async (forceUpdate = false) => {
      if (redeemedStatus.value !== null && !forceUpdate) {
        return redeemedStatus.value
      }
      const gift = await getUserGift()
      redeemedStatus.value = !gift

      return redeemedStatus.value
    }

    const isAMPortalUser = computed<boolean>(
      () => currentRole.value === UserRole.AMPortalUser,
    )
    const isAdminFinance = computed(
      () => currentRole.value === UserRole.AdminFinance,
    )
    const isPartnerFinance = computed(
      () => currentRole.value === UserRole.PartnerFinance,
    )
    const isEmployer = computed(() => currentRole.value === UserRole.Employer)
    const isEmployee = computed(() => currentRole.value === UserRole.Employee)

    const assignRole = (): UserRole => {
      if (!roles.value || roles.value.length === 0) {
        return UserRole.Employee
      }

      if (roles.value.length === 1) {
        return roles.value[0]
      }

      const rolePriority = [
        UserRole.AdminFinance,
        UserRole.PartnerFinance,
        UserRole.Employer,
        UserRole.AMPortalUser,
        UserRole.User,
      ]

      for (const role of rolePriority) {
        if (roles.value.includes(role)) {
          return role
        }
      }

      return UserRole.Employee
    }

    const subscribe = async (status: boolean) => {
      const user = await userApi.subscribe(status)
      updateUserWithUserData(user)
    }

    const getUserData = async () => {
      const user = await userApi.get()
      updateUserWithUserData(user)
    }

    return {
      eyeBotSessionId,
      loading,
      userGift,
      userGiftType,
      getUserGiftType,
      getUserGift,
      startMagicLink,
      loginWithMagicLink,
      loginWithGoogle,
      loginWithEyebot,
      logout,
      credential,
      userToken,
      loggedIn,
      loginError,
      user,
      roles,
      currentRole,
      initials,
      store,
      checkIsRedeemed,
      updateStore,
      isAMPortalUser,
      isAdminFinance,
      isPartnerFinance,
      isEmployer,
      isEmployee,
      subscribe,
      getUserData,
    }
  },
  {
    persist: {
      storage: persistedState.cookiesWithOptions({
        sameSite: 'strict',
        domain: getCookieDomain(),
        secure: process.env.NODE_ENV === 'production',
        maxAge: 3600, // 1 hour
      }),
      paths: [
        'eyeBotSessionId',
        'credential',
        'userToken',
        'loggedIn',
        'user',
        'roles',
        'currentRole',
        'initials',
        'store',
      ],
    },
  },
)

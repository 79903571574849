export const plus = (
  price1: number,
  price2: number,
  precision: number = 2,
): number => {
  // Set the precision, default is 2
  const scaleFactor = Math.pow(10, precision)

  const scaledSum =
    Math.round(price1 * scaleFactor) + Math.round(price2 * scaleFactor)

  return scaledSum / scaleFactor
}

export const subtraction = (
  price1: number,
  price2: number,
  precision = 2,
): number => {
  // Set the precision, default is 2
  const scaleFactor = Math.pow(10, precision)

  const scaledDiff =
    Math.round(price1 * scaleFactor) - Math.round(price2 * scaleFactor)

  return scaledDiff / scaleFactor
}

import { useUserStore } from '@/store/user'
import {
  defineNuxtRouteMiddleware,
  navigateTo,
  useSiteStoreCode,
} from '#imports'

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore()
  const { isEyeBotSiteStore } = useSiteStoreCode()
  if (
    ![
      '/login',
      '/user/order',
      '/tracking',
      '/post-login',
      '/alreadyRedeemed',
      '/eyebotAlreadyRedeemed',
      '/checkout/orderconfirmation',
      '/terms-of-use/employee',
      '/terms-of-use/employer/v1',
      '/terms-of-use/employer',
      '/eyebot',
      '/return-policy',
      '/contact-us',
    ].includes(to.path)
  ) {
    const status = await userStore.checkIsRedeemed()
    if (status) {
      return navigateTo(
        isEyeBotSiteStore.value ? '/eyebotAlreadyRedeemed' : '/alreadyRedeemed',
      )
    }
  }
})
